body {
    @apply w-full h-full overflow-y-hidden;
}

.App {
    @apply absolute w-full h-full lg:flex flex-col lg:flex-row items-center lg:justify-center lg:text-xs overflow-y-auto;
    @apply bg-gradient-to-r from-magenta to-cyan;
    @apply transition-all;
}

.CitiesList {
    @apply justify-center lg:ml-2 lg:flex-initial flex flex-col items-center lg:block mt-4 lg:mt-0;
}

.SelectedCity {
    @apply flex-auto lg:flex-none flex lg:block items-center mb-4 z-10 justify-center;
}

.SelectedCity > * {
   margin: 10px;
}

.footer {
    @apply w-full flex flex-col items-center text-white italic mt-8 mb-4;
    @apply lg:fixed bottom-1;
}

.logo {
    @apply w-24;
}

.fadeIn {
    animation: fadeIn ease 1s;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
