
.city-widget {
    @apply rounded-md h-content bg-gradient-to-r from-gray-100 to-gray-150 text-black p-5 cursor-pointer;
    @apply sm:w-96;
    @apply my-1 sm:my-3 text-xs p-2 sm:m-4 sm:text-xl sm:p-5;
}

.city-widget .header {
    @apply w-full h-10 flex justify-between items-center;
}

.city-widget .strips {
    @apply border-t-4 border-gray-200 ;
}

.city-widget .header .icon {
    @apply text-black;
}

.city-widget .header > span {
    @apply pr-2 text-xs sm:text-xl;
}

.picker {
    @apply text-base text-black p-2 mt-3 sm:mt-8
}

.hues {
    @apply py-2;
}

.picker-metadata {
    @apply text-center w-full mt-2 text-gray-500 not-italic text-xs sm:text-xl;
}

.picker-metadata > span {
    @apply ml-2 capitalize;
}

.hue-picker {
    width: 100% !important;
    @apply mb-2;
}

.hue-picker-turnoff {
    @apply transition-all;
    @apply transform hover:rotate-180;
    @apply mr-2 outline-none border-0;
    @apply focus:outline-none;
}

.intensity {
    @apply w-full justify-between flex;
}

.intensity > button {
    @apply bg-gray-200 rounded px-3 py-1 flex-1 mx-1;
}

.intensity > button {
    @apply outline-none;
}

.non-toggled {
    @apply text-gray-400;
}

.toggled {
    @apply border-0 border-b-4 outline-none text-black border-black;
}

@media (max-width: 900px) {
    .city-widget {
        width: 90vw;
    }
}
